import AOS from "aos";
import React, { useEffect } from "react";
import OwlCarousel from "react-owl-carousel";
import AboutApp from "../HomeMain/AboutApp/Main";
import Design from "../HomeMain/Design/Main";
import Download from "../HomeMain/Download/Main";
import Faq from "../HomeMain/Faq/Main";
import Features from "../HomeMain/Features/Main";
import Interface from "../HomeMain/Interface/Main";
import Story from "../HomeMain/Story/Main";
import Work from "../HomeMain/Work/Main";

import anim from "../../assets/images/anim_line.png";
import blue from "../../assets/images/googleplay_blue.png";
import white from "../../assets/images/googleplay_white.png";
import msg from "../../assets/images/message_icon.png";
import screen from "../../assets/images/screen.png";
import shield from "../../assets/images/shield_icon.png";
import used1 from "../../assets/images/used01.png";
import used2 from "../../assets/images/used02.png";
import used4 from "../../assets/images/used04.png";

const Main = ({ setfooter, setnavbar, setbrdcum }) => {
	useEffect(() => {
		AOS.init();
		AOS.refresh();
	}, []);

	useEffect(() => {
		setfooter({ f1: true });
		setnavbar({ n2: true });
		setbrdcum({ b1: true });
		localStorage.setItem("navbar", "home");
	}, []);

	const frmae_slider = {
		loop: true,
		margin: 10,
		nav: false,
		autoplay: true,
		smartSpeed: 1500,
		dots: true,
		responsive: {
			0: {
				items: 1,
			},
			600: {
				items: 1,
			},
			1000: {
				items: 1,
			},
		},
	};

	return (
		<>
			<section className="banner_section home-banner">
				<div className="container">
					<div className="anim_line">
						<span>
							<img src={anim} alt="anim_line" />
						</span>
						<span>
							<img src={anim} alt="anim_line" />
						</span>
						<span>
							<img src={anim} alt="anim_line" />
						</span>
						<span>
							<img src={anim} alt="anim_line" />
						</span>
						<span>
							<img src={anim} alt="anim_line" />
						</span>
						<span>
							<img src={anim} alt="anim_line" />
						</span>
						<span>
							<img src={anim} alt="anim_line" />
						</span>
						<span>
							<img src={anim} alt="anim_line" />
						</span>
						<span>
							<img src={anim} alt="anim_line" />
						</span>
					</div>
					<div className="row">
						<div
							className="col-lg-6 col-md-12"
							data-aos="fade-right"
							data-aos-duration="1500"
						>
							<div className="banner_text">
								<h1>
									Breaking News <span>at Your Fingertips.</span>
								</h1>
								<p>
									No More Scrolling: Our News Aggregator App Filters the Noise
									and Delivers Only What Matters!
								</p>
							</div>
							<ul className="app_btn">
								{/* <li>
                  <Link to="#">
                    <img className="blue_img" src={blueapp}   />
                    <img className="white_img" src={whiteapp}   />
                  </Link>
                </li> */}
								<li>
									<a
										href="https://play.google.com/store/apps/details?id=app.postgoo.com"
										target="_blank"
										rel="noreferrer"
									>
										<img className="blue_img" src={blue} />
										<img className="white_img" src={white} />
									</a>
								</li>
							</ul>
							<div className="used_app">
								<ul>
									<li>
										<img src={used1} />
									</li>
									<li>
										<img src={used4} />
									</li>
									{/* <li>
                    <img src={used3}   />
                  </li> */}
									<li>
										<img src={used2} />
									</li>
								</ul>
								<p>
									10K+ <br /> used this app
								</p>
							</div>
						</div>
						<div
							className="col-lg-6 col-md-12"
							data-aos="fade-in"
							data-aos-duration="1500"
						>
							<div className="banner_slider">
								<div className="left_icon">
									<img src={msg} />
								</div>
								<div className="right_icon">
									<img src={shield} />
								</div>
								<OwlCarousel
									id="frmae_slider"
									className="owl-carousel owl-theme owl-loaded owl-drag"
									{...frmae_slider}
								>
									<div className="item">
										<div className="slider_img">
											<img src={screen} />
										</div>
									</div>
									{/* <div className="item">
                    <div className="slider_img">
                      <img src={screen}   />
                    </div>
                  </div>
                  <div className="item">
                    <div className="slider_img">
                      <img src={screen}   />
                    </div>
                  </div> */}
								</OwlCarousel>
							</div>
						</div>
					</div>
				</div>
			</section>
			{/* <Trusted /> */}
			<Features />
			<AboutApp />
			<Design />
			<Work />
			{/* <Testimonial /> */}
			{/* <Pricing /> */}
			<Faq />
			<Interface />
			<Download />
			<Story />
		</>
	);
};

export default Main;
